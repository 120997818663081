import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Toaster, toast } from 'react-hot-toast';
import { 
  Calendar,
  Clock,
  FileText,
  Plus,
  CheckCircle,
  XCircle,
  Trash2,
  Download,
  Upload,
  Filter,
  SortAsc,
  SortDesc,
  FileDown,
  X,
  Star,
  MessageCircleWarning
} from 'lucide-react';
import BASE_API_URL from '../config';
import LeaveDetailsModal from '../components/LeaveDetailsModal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import LeaveTable from '../components/LeaveTable';


// OverrideDialog Component
const OverrideDialog = ({ isOpen, onClose, onConfirm, violations, overrideReason, setOverrideReason }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-white rounded-lg p-6 w-full max-w-md"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <h3 className="text-lg font-medium text-gray-900 mb-4">Policy Violations Detected</h3>
          <div className="mb-4">
            <div className="text-sm text-red-600 mb-2">
              The following violations were found:
            </div>
            <ul className="list-disc pl-5 text-sm text-gray-600">
              {violations.map((violation, index) => (
                <li key={index}>{violation}</li>
              ))}
            </ul>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Override Reason (Required)
            </label>
            <textarea
              value={overrideReason}
              onChange={(e) => setOverrideReason(e.target.value)}
              placeholder="Please provide a detailed reason for this override..."
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              rows={4}
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Confirm Override
            </button>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

// Confirmation Dialog Component
const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message, type }) => {
  const [comment, setComment] = useState('');

  const handleConfirm = () => {
    if (type === 'reject' && !comment.trim()) {
      alert('A comment is required to reject this leave.');
      return;
    }
    onConfirm(comment);
    setComment('');
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white rounded-lg p-6 w-full max-w-md"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
          >
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <p className="mt-2 text-sm text-gray-500">{message}</p>

            {/* Textarea for Comment */}
            {type === 'reject' && (
              <div className="mt-4">
                <textarea
                  className="w-full p-2 border rounded-md"
                  placeholder="Add your comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  rows={4}
                />
              </div>
            )}

            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={onClose}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              >
                Confirm
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};


// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
  </div>
);

const LeaveManagementPage = () => {
  // State declarations
  const [showOverrideDialog, setShowOverrideDialog] = useState(false);
  const [violations, setViolations] = useState([]);
  const [overrideReason, setOverrideReason] = useState('');
  const [overrideConfirmed, setOverrideConfirmed] = useState(false);
  const [overridePopup, setOverridePopup] = useState(false);
  const [overrideDetails, setOverrideDetails] = useState({ reason: '', user: '', time: '' });
  const [override, setOverride] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [workerId, setWorkerId] = useState('');
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [file, setFile] = useState(null);
  const [allLeaves, setAllLeaves] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showConfirmDialog, setShowConfirmDialog] = useState({ show: false, type: null, id: null });
  const [justification, setJustification] = useState('');
  const [certificateUploaded, setCertificateUploaded] = useState(false);
  const [dateReceived, setDateReceived] = useState(new Date().toISOString().split('T')[0]);
  const dropdownRef = useRef(null);
  const [selectedLeave, setSelectedLeave] = useState(null);

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <SortAsc className="h-4 w-4 text-gray-400" />;
    return sortConfig.direction === 'asc' 
      ? <SortAsc className="h-4 w-4 text-indigo-600" />
      : <SortDesc className="h-4 w-4 text-indigo-600" />;
      
  };
  // Validation Functions
  const checkLeaveViolations = (startDate, endDate, leaveTypes, allLeaves) => {
    const violations = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();
    const daysNotice = Math.floor((start - today) / (1000 * 60 * 60 * 24));

    // Check for overlapping leaves
    const overlappingLeave = allLeaves.some((leave) => 
      leave.workerId === workerId &&
      leave.status !== 'Rejected' &&
      new Date(leave.startDate) <= new Date(endDate) && 
      new Date(leave.endDate) >= new Date(startDate)
    );
    
    if (overlappingLeave) {
      violations.push("Leave request overlaps with existing approved or pending leave period");
    }

    // Check leave type specific rules
    if (leaveTypes.includes("Annual Leave") && daysNotice < 28) {
      violations.push("Annual Leave requires 4 weeks notice");
    }

    if (leaveTypes.includes("RDO")) {
      if (leaveTypes.length === 1 && daysNotice < 2) {
        violations.push("Single RDO requires at least 48 hours notice");
      } else if (leaveTypes.length > 1 && daysNotice < 7) {
        violations.push("Consecutive RDOs require 1 week's notice");
      }
    }

    if (leaveTypes.includes("Long Service Leave") && daysNotice < 28) {
      violations.push("Long Service Leave requires 4 weeks notice");
    }

    if (leaveTypes.includes("Wellbeing Leave") && daysNotice < 28) {
      violations.push("Wellbeing Leave requires 4 weeks notice");
    }

    if ((leaveTypes.includes("Personal Leave") || leaveTypes.includes("Carer's Leave"))) {
      const duration = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
      if (duration > 1 && !certificateUploaded) {
        violations.push("A medical certificate is required for Personal/Carer's Leave longer than one day");
      }
    }

    if (leaveTypes.includes("Other") && !justification.trim()) {
      violations.push("Justification is required for Other leave type");
    }

    return violations;
  };

  // Effect Hooks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    fetchLeaves();
  }, [sortConfig]);

  useEffect(() => {
    const searchWorkers = async () => {
      if (searchTerm.length < 2) {
        setSearchResults([]);
        return;
      }
      try {
        const response = await axios.get(`${BASE_API_URL}/api/workers/search/${searchTerm}`);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error searching workers:', error);
        toast.error('Error searching workers');
      }
    };
    const timeoutId = setTimeout(searchWorkers, 300);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // API Functions
  const fetchLeaves = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_API_URL}/api/leaves`);
      let leaves = response.data;

      if (sortConfig.key) {
        leaves.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
      }

      setAllLeaves(leaves);
    } catch (error) {
      console.error('Error fetching leaves:', error);
      toast.error('Error fetching leaves');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    if (!startDate || !endDate || !leaveTypes.length || !workerId) {
      toast.error("Please fill in all required fields");
      return;
    }
  
    // Check for leave violations
    const violations = checkLeaveViolations(startDate, endDate, leaveTypes, allLeaves);
    if (violations.length > 0 && !overrideConfirmed) {
      setViolations(violations);
      setShowOverrideDialog(true);
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("startDate", startDate); // Ensure YYYY-MM-DD
      formData.append("endDate", endDate);
      formData.append("leaveTypes", JSON.stringify(leaveTypes)); // Backend expects JSON
      formData.append("workerId", workerId);
      formData.append("workerName", formatName(selectedWorker?.Name || "N/A")); // Format name if needed
      formData.append("dateReceived", dateReceived); // Ensure YYYY-MM-DD
      formData.append("submittedBy", JSON.parse(localStorage.getItem("user"))?.firstName || "Unknown");
  
      // Handle override fields
      if (overrideConfirmed) {
        formData.append("override", "true"); // Backend expects string or boolean
        formData.append("overrideReason", overrideReason || "");
        formData.append("overrideBy", JSON.parse(localStorage.getItem("user"))?.firstName || "Unknown");
        formData.append("overrideTime", new Date().toISOString());
      }
  
      // Attach optional file
      if (file) {
        formData.append("file", file);
      }
  
      // Add justification if provided
      if (justification) {
        formData.append("justification", justification);
      }
  
      // Log data for debugging
      console.log("Submitting data:", Object.fromEntries(formData.entries()));
  
      // Submit form data to backend
      await axios.post(`${BASE_API_URL}/api/leaves`, formData);
  
      toast.success("Leave request submitted successfully!");
      setShowForm(false);
      fetchLeaves();
  
      // Reset state after submission
      setOverrideConfirmed(false);
      setOverrideReason("");
      setShowOverrideDialog(false);
      setJustification("");
      setCertificateUploaded(false);
      setFile(null);
      setLeaveTypes([]);
      setStartDate("");
      setEndDate("");
      setWorkerId("");
      setSelectedWorker(null);
      setSearchTerm("");
    } catch (error) {
      console.error("Error submitting leave:", error);
      toast.error(error.response?.data?.message || "Failed to submit leave request");
    }
  };
  
  // Optional: Utility function to format names (e.g., "JONES, Leslie" -> "Leslie Jones")
  const formatName = (name) => {
    const [lastName, firstName] = name.split(", ");
    return firstName ? `${firstName.trim()} ${lastName.trim()}` : name;
  };
  

  const handleApproveReject = async (id, status, comment = '') => {
    try {
      const response = await axios.put(`${BASE_API_URL}/api/leaves/${id}/approve`, {
        status,
        approvedBy: JSON.parse(localStorage.getItem("user"))?.firstName || "Unknown",
        comment, // Pass the comment to the backend
      });
      toast.success(`Leave request ${status.toLowerCase()} successfully!`);
      fetchLeaves(); // Refresh leaves after the operation
    } catch (error) {
      console.error(`Error ${status.toLowerCase()}ing leave:`, error);
      toast.error(`Failed to ${status.toLowerCase()} leave request`);
    }
  };
  
  

  const handleDeleteLeave = async (leaveId) => {
    try {
      await axios.delete(`${BASE_API_URL}/api/leaves/${leaveId}`);
      toast.success('Leave request deleted successfully');
      fetchLeaves();
    } catch (error) {
      console.error('Error deleting leave:', error);
      toast.error('Failed to delete leave request');
    }
  };

  // File Handling Functions
const handleFileUpload = async (file, leave) => {
  if (!file) {
    toast.error('No file selected');
    return;
  }

  try {
    // Prepare the form data with required metadata
    const formData = new FormData();
    formData.append('file', file);
    formData.append('leaveId', leave.id);
    formData.append('workerName', leave.workerName); // Pass worker name
    formData.append('leaveType', leave.leaveTypes); // Pass leave type
    formData.append('startDate', leave.startDate); // Pass start date
    formData.append('endDate', leave.endDate); // Pass end date

    if (leave.dateReceived) {
      formData.append('dateReceived', leave.dateReceived); // Include date received if available
    }

    // Send the request to the backend
    await axios.post(`${BASE_API_URL}/api/leaves/upload`, formData);

    toast.success('File and metadata uploaded successfully');
    fetchLeaves(); // Refresh the leave list
  } catch (error) {
    console.error('Error uploading file:', error);
    toast.error('Failed to upload file');
  }
};

  const handleDownload = async (fileUrl) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/leaves/download`, {
        params: { fileUrl },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileUrl.split('/').pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  const handleDownloadOrUpload = async (leave) => {
    if (leave.fileUrl) {
      try {
        const filePath = leave.fileUrl.split("/").slice(-2).join("/");
        const response = await axios.get(`${BASE_API_URL}/api/leaves/download`, {
          params: { filePath },
        });
        window.open(response.data.signedUrl, "_blank");
      } catch (error) {
        console.error("Error fetching signed URL:", error);
        toast.error("Failed to download the file");
      }
    } else {
      const fileInput = document.getElementById(`fileInput-${leave.id}`);
      if (fileInput) fileInput.click();
    }
  };

  // UI Helper Functions
  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'Pending': return 'bg-yellow-100 text-yellow-800';
      case 'Approved': return 'bg-green-100 text-green-800';
      case 'Rejected': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const selectWorker = (worker) => {
    setSelectedWorker(worker);
    setWorkerId(worker['Employee Number']);
    setSearchTerm(worker.Name);
    setSearchResults([]);
  };

  const prepareCSVData = () => {
    const displayedLeaves = 
      activeTab === 'pending' ? allLeaves.filter(leave => leave.status === 'Pending') :
      activeTab === 'approved' ? allLeaves.filter(leave => leave.status === 'Approved') :
      activeTab === 'rejected' ? allLeaves.filter(leave => leave.status === 'Rejected') :
      allLeaves;

      return displayedLeaves.map((leave) => ({
        'Employee Name': leave.workerName || 'N/A',
        'Employee ID': leave.workerId,
        'Leave Type': Array.isArray(leave.leaveTypes) ? leave.leaveTypes.join(", ") : leave.leaveTypes,
        'Start Date': format(new Date(leave.startDate), 'yyyy-MM-dd'),
        'End Date': format(new Date(leave.endDate), 'yyyy-MM-dd'),
        'Status': leave.status,
        'Rejection Comment': leave.rejectionComment || '',
        'Submitted By': leave.submittedBy || 'N/A',
        'Submitted Date': leave.submittedAt ? format(new Date(leave.submittedAt), 'yyyy-MM-dd HH:mm') : 'N/A',
        'Actioned By': leave.actionBy || 'N/A',
        'Actioned Date': leave.actionAt ? format(new Date(leave.actionAt), 'yyyy-MM-dd HH:mm') : 'N/A',
        'Override': leave.override ? 'Yes' : 'No',
        'Override Reason': leave.overrideReason || 'No Override',
        'Comments': leave.comments
          ? leave.comments
              .map(
                (comment) =>
                  `Comment: ${comment.comment}, By: ${comment.commentedBy || 'N/A'}, At: ${
                    comment.commentedAt ? format(new Date(comment.commentedAt), 'yyyy-MM-dd HH:mm') : 'N/A'
                  }`
              )
              .join(' | ') // Separate each comment with a delimiter
          : 'No Comments',
      }));
      
  };

  // Form JSX
  const renderLeaveForm = () => (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900">Submit Leave Request</h2>
          <button
            onClick={() => setShowForm(false)}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative" ref={dropdownRef}>
            <label className="block text-sm font-medium text-gray-700">
              Search Worker
            </label>
            <input
              type="text"
              placeholder="Type to search worker..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-lg p-3"
            />
            {searchResults.length > 0 && (
              <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto z-50">
                {searchResults.map((worker) => (
                  <div
                    key={worker.id}
                    onClick={() => selectWorker(worker)}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="font-medium text-gray-900">{worker.Name}</div>
                    <div className="text-sm text-gray-500">
                      ID: {worker['Employee Number']}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-lg p-3"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-lg p-3"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Leave Types</label>
            <div className="grid grid-cols-2 gap-4 mt-4">
  {[
    "Annual Leave",
    "Personal Leave",
    "Carer's Leave",
    "RDO",
    "Leave Without Pay",
    "Long Service Leave",
    "Wellbeing Leave",
    "Other"
  ].map((type) => (
    <label
      key={type}
      htmlFor={`leaveTypes-${type}`}
      className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg shadow-sm hover:bg-gray-100"
    >
      <input
        type="checkbox"
        id={`leaveTypes-${type}`}
        value={type}
        checked={leaveTypes.includes(type)}
        onChange={(e) => {
          if (e.target.checked) {
            setLeaveTypes([...leaveTypes, type]);
          } else {
            setLeaveTypes(leaveTypes.filter((t) => t !== type));
            if (type === "Other") setJustification("");
          }
        }}
        className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
      />
      <span className="text-gray-800 font-medium">{type}</span>
    </label>
  ))}
</div>

          </div>

          {leaveTypes.includes("Other") && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Justification for Other Leave
              </label>
              <textarea
                value={justification}
                onChange={(e) => setJustification(e.target.value)}
                placeholder="Please provide details for your Other leave request"
                className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows={3}
              />
            </div>
          )}
          <div>
  <label className="block text-sm font-medium text-gray-700">
    Supporting Document
  </label>
  <div className="mt-1 flex items-center">
    <input
      type="file"
      onChange={(e) => {
        setFile(e.target.files[0]); // Set file in state
        setCertificateUploaded(true); // Indicate file has been uploaded
      }}
      className="block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-md file:border-0
        file:text-sm file:font-semibold
        file:bg-indigo-50 file:text-indigo-700
        hover:file:bg-indigo-100"
    />
  </div>
  <p className="mt-2 text-sm text-gray-500">
    Upload any supporting documents (Medical Cert. required for Personal/Carer's Leave over one day)
  </p>
</div>

{file && ( // Conditionally render Date Received input
  <div className="mt-4">
    <label className="block text-sm font-medium text-gray-700">
      Date Form Received
    </label>
    <input
      type="date"
      value={dateReceived}
      onChange={(e) => setDateReceived(e.target.value)} // Update dateReceived state
      className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-lg p-3"
      required
    />
  </div>
)}


          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Submit Request
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
    );

  return (
    <div className="min-h-screen p-8 bg-gray-50 space-y-8">
      <Toaster position="top-right" />
  
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Leave Management</h1>
        <button
          onClick={() => setShowForm(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Leave Request
        </button>
      </div>
  
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Clock className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">Pending Approvals</dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">
                      {allLeaves.filter(leave => leave.status === 'Pending').length}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
  
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FileText className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">Total Leave Requests</dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">{allLeaves.length}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 px-6" aria-label="Tabs">
            {['pending', 'approved', 'rejected', 'all'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize`}
              >
                {tab}
                <span className="ml-2 bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                  {tab === 'pending' 
                    ? allLeaves.filter(leave => leave.status === 'Pending').length
                    : tab === 'approved'
                    ? allLeaves.filter(leave => leave.status === 'Approved').length
                    : tab === 'rejected'
                    ? allLeaves.filter(leave => leave.status === 'Rejected').length
                    : allLeaves.length}
                </span>
              </button>
            ))}
          </nav>
        </div>
  
        <div className="p-6">
          <div className="overflow-x-auto">
            <div className="flex justify-end mb-4">
              <CSVLink 
                data={prepareCSVData()}
                filename={`leaves-${activeTab}-${format(new Date(), 'yyyy-MM-dd')}.csv`}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <FileDown className="h-4 w-4 mr-2" />
                Export CSV
              </CSVLink>
            </div>
            <LeaveTable
  allLeaves={allLeaves}
  activeTab={activeTab}
  handleSort={handleSort}
  setShowConfirmDialog={setShowConfirmDialog}
  handleDownloadOrUpload={handleDownloadOrUpload}
  handleFileUpload={handleFileUpload}
  setSelectedLeave={setSelectedLeave}
  selectedLeave={selectedLeave}
/>
             {isLoading && <LoadingSpinner />}
             {!isLoading && allLeaves.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No leaves found
              </div>
            )}
          </div>
        </div>
      </div>
  
      {showForm && renderLeaveForm()}

      <OverrideDialog 
        isOpen={showOverrideDialog}
        onClose={() => setShowOverrideDialog(false)}
        onConfirm={() => {
          if (!overrideReason.trim()) {
            toast.error('Please provide a reason for the override');
            return;
          }
          setOverrideConfirmed(true);
          setShowOverrideDialog(false);
          handleSubmit(new Event('submit'));
        }}
        violations={violations}
        overrideReason={overrideReason}
        setOverrideReason={setOverrideReason}
      />

<ConfirmDialog
  isOpen={showConfirmDialog.show}
  onClose={() => setShowConfirmDialog({ show: false, type: null, id: null })}
  onConfirm={(comment) => {
    switch (showConfirmDialog.type) {
      case 'approve':
        handleApproveReject(showConfirmDialog.id, 'Approved');
        break;
      case 'reject':
        handleApproveReject(showConfirmDialog.id, 'Rejected', comment);
        break;
      case 'delete':
        handleDeleteLeave(showConfirmDialog.id);
        break;
    }
    setShowConfirmDialog({ show: false, type: null, id: null });
  }}
  title={`Confirm ${showConfirmDialog.type}`}
  message={`Are you sure you want to ${showConfirmDialog.type} this leave request?`}
  type={showConfirmDialog.type} // Pass the action type
/>

    </div>
  );
};

export default LeaveManagementPage;