import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-modal';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import BASE_API_URL from '../config';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
  },
};

const convertExcelDate = (serial) => {
  if (typeof serial === 'number') {
    const excelEpoch = new Date(1900, 0, serial - 1);
    return excelEpoch.toISOString().split('T')[0];
  }
  return serial;
};

const WorkerManagement = () => {
  const [workers, setWorkers] = useState([]); // Main data store
  const [rowData, setRowData] = useState([]); // Filtered table data
  const [worker, setWorker] = useState({
    employeeNumber: '',
    name: '',
    startDate: '',
    supervisor: '',
    position: '',
    roster: '',
    section: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch data on component mount
  useEffect(() => {
    fetchWorkers();
  }, []);

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/workers`);
      const sanitizedData = response.data.map((worker) => ({
        id: worker.id,
        employeeNumber: worker['Employee Number'],
        name: worker.Name,
        startDate: convertExcelDate(worker['Start Date']),
        supervisor: worker.Supervisor,
        position: worker.Position,
        roster: worker.Roster,
        section: worker.Section,
      }));
      setWorkers(sanitizedData);
      setRowData(sanitizedData); // Initialize table with all data
    } catch (error) {
      console.error('Error fetching workers:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorker((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddWorker = async () => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/workers/add`, worker);
      const newWorker = {
        ...response.data,
        startDate: convertExcelDate(response.data.startDate),
      };
      setWorkers((prev) => [...prev, newWorker]); // Add new worker locally
      setRowData((prev) => [...prev, newWorker]); // Update table
      alert('Worker added successfully');
      closeModal();
    } catch (error) {
      console.error('Error adding worker:', error);
    }
  };

  const handleUpdateWorker = async () => {
    try {
      const response = await axios.put(`${BASE_API_URL}/api/workers/${worker.id}`, worker);
      const updatedWorker = {
        ...response.data,
        startDate: convertExcelDate(response.data.startDate),
      };
      setWorkers((prev) =>
        prev.map((w) => (w.id === updatedWorker.id ? updatedWorker : w))
      ); // Update locally
      setRowData((prev) =>
        prev.map((w) => (w.id === updatedWorker.id ? updatedWorker : w))
      );
      alert('Worker updated successfully');
      closeModal();
    } catch (error) {
      console.error('Error updating worker:', error);
    }
  };

  const handleDeleteWorker = async (id) => {
    try {
      await axios.delete(`${BASE_API_URL}/api/workers/${id}`);
      setWorkers((prev) => prev.filter((worker) => worker.id !== id)); // Remove locally
      setRowData((prev) => prev.filter((worker) => worker.id !== id));
      alert('Worker deleted successfully');
    } catch (error) {
      console.error('Error deleting worker:', error);
    }
  };

  const filteredRowData = useMemo(() => {
    if (!searchTerm) return rowData;
    return workers.filter((worker) =>
      Object.values(worker).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [rowData, searchTerm, workers]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditWorker = (worker) => {
    setWorker(worker);
    setIsEditMode(true);
    setModalIsOpen(true);
  };

  const openModal = () => {
    setWorker({
      employeeNumber: '',
      name: '',
      startDate: '',
      supervisor: '',
      position: '',
      roster: '',
      section: '',
    });
    setIsEditMode(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const columnDefs = [
    { headerName: 'Employee Number', field: 'employeeNumber', sortable: true, filter: true },
    { headerName: 'Name', field: 'name', sortable: true, filter: true },
    { headerName: 'Start Date', field: 'startDate', sortable: true, filter: true },
    { headerName: 'Supervisor', field: 'supervisor', sortable: true, filter: true },
    { headerName: 'Position', field: 'position', sortable: true, filter: true },
    { headerName: 'Roster', field: 'roster', sortable: true, filter: true },
    { headerName: 'Section', field: 'section', sortable: true, filter: true },
    {
      headerName: 'Actions',
      cellRenderer: (params) => (
        <div className="flex space-x-2">
          <Button
            onClick={() => handleEditWorker(params.data)}
            className="bg-blue-600 hover:bg-blue-700 text-white"
            size="sm"
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteWorker(params.data.id)}
            className="bg-red-600 hover:bg-red-700 text-white"
            size="sm"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <Card className="max-w-8xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-gray-800">Blue-Collar Management</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full"
            />
          </div>
          <div className="mb-4">
            <Button onClick={openModal} className="bg-blue-600 hover:bg-blue-700 text-white">
              Add Worker
            </Button>
          </div>
          <div className="ag-theme-alpine" style={{ height: '900px', width: '100%' }}>
            <AgGridReact
              rowData={filteredRowData}
              columnDefs={columnDefs}
              defaultColDef={{ flex: 1, minWidth: 150 }}
              pagination={true}
              paginationPageSize={20}
            />
          </div>
        </CardContent>
      </Card>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles}>
        <h2 className="text-lg font-bold mb-4">{isEditMode ? 'Edit Worker' : 'Add Worker'}</h2>
        <Input
          type="text"
          name="employeeNumber"
          placeholder="Employee Number"
          value={worker.employeeNumber}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={worker.name}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="date"
          name="startDate"
          value={worker.startDate}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="supervisor"
          placeholder="Supervisor"
          value={worker.supervisor}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="position"
          placeholder="Position"
          value={worker.position}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="roster"
          placeholder="Roster"
          value={worker.roster}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="section"
          placeholder="Section"
          value={worker.section}
          onChange={handleInputChange}
          className="mb-4 w-full"
        />
        <Button
          onClick={isEditMode ? handleUpdateWorker : handleAddWorker}
          className="bg-blue-600 hover:bg-blue-700 text-white"
        >
          {isEditMode ? 'Update Worker' : 'Add Worker'}
        </Button>
        <Button onClick={closeModal} className="bg-gray-500 hover:bg-gray-600 text-white mt-2">
          Cancel
        </Button>
      </Modal>
    </div>
  );
};

export default WorkerManagement;
