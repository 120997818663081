import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import CreateAccountPage from './pages/CreateAccountPage';
import WorkerManagement from './pages/WorkerManagement';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import RosterPage from './pages/RosterPage';
import LeaveFormPage from './pages/LeaveFormPage';
import './output.css';
import { RosterProvider } from './context/rosterContext'; // Import RosterProvider
import UserProfileManagement from './pages/UserProfileManagement';
import ForgotPasswordPage from './pages/ForgotPasswordPage'; // Import the ForgotPasswordPage

// PrivateLayout component now has access to RosterProvider
const PrivateLayout = ({ children }) => {
  return (
    <PrivateRoute>
      <div className="min-h-screen bg-gray-50 flex overflow-hidden">
        <Navbar />
        <div className="flex-1 ml-72 h-full overflow-auto">
          <main className="h-screen p-4">
            {children}
          </main>
        </div>
      </div>
    </PrivateRoute>
  );
};

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Routes>
          <Route 
            path="/" 
            element={
              <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600">
                <LoginPage />
              </div>
            } 
          />
          <Route 
            path="/create-account" 
            element={
              <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600">
                <CreateAccountPage />
              </div>
            } 
          />
           <Route 
            path="/forgot-password" 
            element={
              <div className="min-h-screen bg-gray-50 flex items-center justify-center">
                <ForgotPasswordPage />
              </div>
            }
            />
          {/* Wrap all PrivateLayout routes inside RosterProvider */}
          <Route
            path="/dashboard"
            element={
              <RosterProvider>
                <PrivateLayout>
                  <Dashboard />
                </PrivateLayout>
              </RosterProvider>
            }
          />
          <Route
            path="/workers"
            element={
              <RosterProvider>
                <PrivateLayout>
                  <WorkerManagement />
                </PrivateLayout>
              </RosterProvider>
            }
          />
          <Route
            path="/roster"
            element={
              <RosterProvider>
                <PrivateLayout>
                  <RosterPage />
                </PrivateLayout>
              </RosterProvider>
            }
          />
          <Route
            path="/leave-form"
            element={
              <RosterProvider>
                <PrivateLayout>
                  <LeaveFormPage />
                </PrivateLayout>
              </RosterProvider>
            }
          />
          <Route
            path="/profile"
            element={
              <RosterProvider>
                <PrivateLayout>
                  <UserProfileManagement />
                </PrivateLayout>
              </RosterProvider>
            }
          />
          <Route
            path="*"
            element={
              <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
                <div className="text-center space-y-4">
                  <h1 className="text-6xl font-bold text-indigo-600">404</h1>
                  <h2 className="text-2xl font-semibold text-gray-800">Page Not Found</h2>
                  <p className="text-gray-600 max-w-md mx-auto">
                    The page you're looking for doesn't exist or has been moved.
                  </p>
                  <div className="mt-6">
                    <Link
                      to="/dashboard"
                      className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-200"
                    >
                      Return to Dashboard
                    </Link>
                  </div>
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
