import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import {
  User,
  Calendar,
  CalendarArrowUp,
  CalendarArrowDown,
  RectangleEllipsis,
  CalendarPlus,
  FileText,
  MessageSquare,
  Trash2,
  Clock,
  Upload,
  Download,
} from 'lucide-react';
import BASE_API_URL from '../config';

const LeaveDetailsModal = ({ leave, isOpen, onClose, onUpdateParent}) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [file, setFile] = useState(null);
  const [currentLeave, setCurrentLeave] = useState(leave);
  const [dateReceived, setDateReceived] = useState(leave?.dateReceived || '');

  const currentUser = JSON.parse(localStorage.getItem('user'))?.firstName || 'Unknown';

  useEffect(() => {
    if (isOpen) {
      fetchComments();
      setDateReceived(leave.dateReceived || '');
    }
  }, [isOpen, leave]);

  useEffect(() => {
    if (leave) {
      setCurrentLeave(leave);
      setDateReceived(leave.dateReceived || '');
    }
  }, [leave]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/leaves/${leave.id}/comments`);
      setComments(response.data.comments || []);
    } catch (error) {
      console.error('Error fetching comments:', error);
      toast.error('Failed to fetch comments');
    }
  };

  const fetchUpdatedLeave = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/leaves/${currentLeave.id}`);
      setCurrentLeave(response.data); // Update modal's local leave state
      if (onUpdateParent) {
        onUpdateParent(response.data); // Notify parent to update its state
      }
    } catch (error) {
      console.error('Error fetching updated leave:', error);
      toast.error('Failed to refresh leave details');
    }
  };
 
  const handleAddComment = async () => {
    if (!newComment.trim()) {
      toast.error('Comment cannot be empty');
      return;
    }
    try {
      const response = await axios.post(`${BASE_API_URL}/api/leaves/comment`, {
        leaveId: leave.id,
        comment: newComment,
        commentedBy: currentUser,
      });
      setComments(response.data.comments);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/leaves/comment/delete`, {
        leaveId: leave.id,
        commentId,
      });
      setComments(response.data.comments);
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };
  const handleDownloadOrUpload = async (leave) => {
    if (leave.fileUrl) {
      try {
        const filePath = leave.fileUrl.split("/").slice(-2).join("/");
        const response = await axios.get(`${BASE_API_URL}/api/leaves/download`, {
          params: { filePath },
        });
        window.open(response.data.signedUrl, "_blank");
      } catch (error) {
        console.error("Error fetching signed URL:", error);
        toast.error("Failed to download the file");
      }
    } else {
      const fileInput = document.getElementById(`fileInput-${leave.id}`);
      if (fileInput) fileInput.click();
    }
  };
  

  const handleFileUpload = async () => {
    if (!file) {
      toast.error('No file selected');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('leaveId', leave.id);
      formData.append('workerName', leave.workerName || 'Unknown');
      formData.append('leaveType', leave.leaveTypes || 'Unknown');
      formData.append('startDate', leave.startDate || '');
      formData.append('endDate', leave.endDate || '');
      formData.append('dateReceived', dateReceived || '');
  
      console.log("Uploading file with the following data:");
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      await axios.post(`${BASE_API_URL}/api/leaves/upload`, formData);
      toast.success('File uploaded successfully');
      fetchUpdatedLeave();
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    }
  };
 
  return (
    isOpen && (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-white rounded-lg shadow-lg p-8 w-full max-w-2xl relative"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            ✕
          </button>
          <h2 className="text-3xl font-bold mb-6 text-indigo-600">Leave Details</h2>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="flex items-center">
              <User className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>Employee:</strong> {leave.workerName}
              </p>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>Leave Type:</strong>{' '}
                {Array.isArray(leave.leaveTypes) ? leave.leaveTypes.join(', ') : leave.leaveTypes || 'N/A'}
              </p>
            </div>
            <div className="flex items-center">
              <CalendarArrowUp className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>Start Date:</strong> {format(new Date(leave.startDate), 'yyyy-MM-dd')}
              </p>
            </div>
            <div className="flex items-center">
              <CalendarArrowDown className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>End Date:</strong> {format(new Date(leave.endDate), 'yyyy-MM-dd')}
              </p>
            </div>
            <div className="flex items-center">
              <RectangleEllipsis className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>Status:</strong>{' '}
                <span
                  className={`font-semibold ${
                    leave.status === 'Approved'
                      ? 'text-green-600'
                      : leave.status === 'Rejected'
                      ? 'text-red-600'
                      : 'text-yellow-600'
                  }`}
                >
                  {leave.status}
                </span>
              </p>
            </div>
            <div className="flex items-center">
              <CalendarPlus className="mr-2 text-indigo-600 h-5 w-5" />
              <p>
                <strong>Date Received:</strong> {leave.dateReceived || 'N/A'}
              </p>
            </div>
            {leave.status === 'Rejected' && leave.rejectionComment && (
              <div className="flex items-center">
                <FileText className="mr-2 text-indigo-600 h-5 w-5" />
                <p>
                  <strong>Rejection Comment:</strong> {leave.rejectionComment}
                </p>
              </div>
            )}
            {leave.override && (
              <>
                <div className="flex items-center">
                  <Calendar className="mr-2 text-indigo-600 h-5 w-5" />
                  <p>
                    <strong>Override By:</strong> {leave.overrideBy || 'N/A'}
                  </p>
                </div>
                <div className="flex items-center">
                  <Clock className="mr-2 text-indigo-600 h-5 w-5" />
                  <p>
                    <strong>Override Time:</strong>{' '}
                    {leave.overrideTime ? format(new Date(leave.overrideTime), 'yyyy-MM-dd HH:mm') : 'N/A'}
                  </p>
                </div>
                <div className="flex items-center">
                  <FileText className="mr-2 text-indigo-600 h-5 w-5" />
                  <p>
                    <strong>Override Reason:</strong> {leave.overrideReason || 'N/A'}
                  </p>
                </div>
              </>
            )}
          </div>
          {/* Comments Section */}
          <hr className="my-6 border-t-2 border-indigo-600" />
          <h3 className="text-2xl font-semibold mb-4 text-indigo-600 flex items-center">
            <MessageSquare className="mr-2" /> Comments
          </h3>
          <ul className="space-y-4 mb-6 max-h-60 overflow-y-auto">
            {comments.map((c) => (
              <li key={c.commentId} className="bg-gray-50 p-4 rounded-lg shadow">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-gray-800">{c.comment}</p>
                    <p className="text-sm text-gray-500 mt-1">
                      By {c.commentedBy} on {format(new Date(c.commentedAt), 'yyyy-MM-dd HH:mm')}
                    </p>
                  </div>
                  {c.commentedBy === currentUser && (
                    <button
                      onClick={() => handleDeleteComment(c.commentId)}
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      <Trash2 />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <textarea
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:border-transparent transition-all mb-4"
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            rows="3"
          />
          <button
            onClick={handleAddComment}
            className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            Add Comment
          </button>
          {/* File Management Section */}
<hr className="my-6 border-t-2 border-indigo-600" />
<h3 className="text-2xl font-semibold mb-4 text-indigo-600 flex items-center">
  <FileText className="mr-2" /> Supporting Documents
</h3>
{leave.fileUrl ? (
  <>
    <div className="flex items-center mb-4">
      <p className="text-lg font-semibold text-gray-700">
        <strong>Date Received:</strong> {dateReceived}
      </p>
    </div>
    <div className="flex items-center justify-start mt-4 space-x-4">
      <div
        className="flex items-center justify-center w-16 h-16 bg-gray-200 border border-gray-300 rounded-lg cursor-pointer hover:bg-indigo-50 transition"
        onClick={() => handleDownloadOrUpload(leave)}
        title="Download File"
      >
        <Download className="w-8 h-8 text-indigo-600" />
      </div>
      <p className="text-sm text-gray-500">Click the icon to download the document.</p>
    </div>
  </>
) : (
  <>
    <div className="flex items-center mb-4">
      <p className="text-lg font-semibold text-gray-700">
        <strong>Date Received:</strong>
      </p>
      <input
        type="date"
        value={dateReceived}
        onChange={(e) => setDateReceived(e.target.value)}
        className="ml-4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600"
      />
    </div>
    <div
      className="flex items-center justify-center mt-4 p-6 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-indigo-600 transition"
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length) {
          setFile(droppedFiles[0]);
          toast.success(`${droppedFiles[0].name} added for upload.`);
        }
      }}
      onClick={() => document.getElementById(`fileInput-${leave.id}`).click()}
    >
      <input
        type="file"
        id={`fileInput-${leave.id}`}
        style={{ display: 'none' }}
        onChange={(e) => setFile(e.target.files[0])}
      />
      <div className="flex flex-col items-center">
        <Upload className="w-8 h-8 text-indigo-600 mb-2" />
        <p className="text-sm text-gray-500">Drag and drop or click to upload a document.</p>
      </div>
    </div>
    {file && (
      <div className="mt-4">
        <p className="text-sm text-gray-700">
          <strong>Selected File:</strong> {file.name}
        </p>
        <button
          onClick={handleFileUpload}
          className="mt-2 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 shadow-md transition"
        >
          Upload
        </button>
      </div>
    )}
  </>
)}


        </motion.div>
      </motion.div>
    )
  );
};

export default LeaveDetailsModal;
