import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import LeaveDetailsModal from './LeaveDetailsModal';
import { AlertTriangle, Trash2, CheckCircle, XCircle } from 'lucide-react';
import { format } from 'date-fns';

const LeaveTable = ({
  allLeaves, // Already filtered in LeaveFormPage
  setAllLeaves, // Parent function to update all leaves
  activeTab,
  setShowConfirmDialog,
  setSelectedLeave,
  selectedLeave,
}) => {
    // Filter leaves based on activeTab
    const filteredLeaves = useMemo(() => {
      return allLeaves.filter((leave) => {
        switch (activeTab) {
          case 'pending':
            return leave.status === 'Pending';
          case 'approved':
            return leave.status === 'Approved';
          case 'rejected':
            return leave.status === 'Rejected';
          default:
            return true;
        }
      });
    }, [allLeaves, activeTab]);
  // Helper function for status badge colors
  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Approved':
        return 'bg-green-100 text-green-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleLeaveUpdate = (updatedLeave) => {
    const updatedLeaves = allLeaves.map((leave) =>
      leave.id === updatedLeave.id ? updatedLeave : leave
    );
    setAllLeaves(updatedLeaves);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Employee Name',
        field: 'workerName',
        sortable: true,
        filter: true,
        cellRenderer: ({ value, data }) => (
          <div className="flex items-center space-x-2">
            <span>{value || 'N/A'}</span>
            {data.comments && data.comments.length > 0 && (
              <AlertTriangle className="text-yellow-500 h-4 w-4" title="Has comments" />
            )}
          </div>
        ),
      },
      { headerName: 'Employee ID', field: 'workerId', sortable: true, filter: true },
      {
        headerName: 'Leave Type',
        field: 'leaveTypes',
        filter: true,
        cellRenderer: ({ value }) => (
          <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800">
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ),
      },
      {
        headerName: 'Duration',
        field: 'startDate',
        sortable: true,
        filter: 'agDateColumnFilter',
        cellRenderer: ({ data }) => (
          <div className="flex items-center space-x-2">
            <span>
              {data.startDate} to {data.endDate}
            </span>
            <span className="inline-flex rounded-full px-2 text-xs font-semibold bg-gray-100 text-gray-800">
              {data.daysOfLeave} days
            </span>
          </div>
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        filter: true,
        cellRenderer: ({ value, data }) => (
          <div>
            <span
              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getStatusBadgeColor(
                value
              )}`}
            >
              {value}
            </span>
            {data.override && <span className="ml-2 text-sm">Override</span>}
          </div>
        ),
      },
      { headerName: 'Submitted By', field: 'submittedBy', filter: true },
      {
        headerName: 'Submitted Date',
        field: 'submittedAt',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => (value ? format(new Date(value), 'dd-MMM-yyyy HH:mm') : 'N/A'),
      },
      { headerName: 'Actioned By', field: 'actionBy', filter: true },
      {
        headerName: 'Actioned Date',
        field: 'actionAt',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => (value ? format(new Date(value), 'dd-MMM-yyyy HH:mm') : 'N/A'),
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ({ data }) => (
          <div className="flex space-x-2">
            {activeTab === 'pending' && (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDialog({ show: true, type: 'approve', id: data.id });
                  }}
                  className="inline-flex items-center px-3 py-1 border border-transparent rounded-md text-sm text-white bg-green-600 hover:bg-green-700"
                >
                  <CheckCircle className="h-4 w-4 mr-1" />
                  Approve
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDialog({ show: true, type: 'reject', id: data.id });
                  }}
                  className="inline-flex items-center px-3 py-1 border border-transparent rounded-md text-sm text-white bg-red-600 hover:bg-red-700"
                >
                  <XCircle className="h-4 w-4 mr-1" />
                  Reject
                </button>
              </>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowConfirmDialog({ show: true, type: 'delete', id: data.id });
              }}
              className="inline-flex items-center px-2 py-1 border border-gray-300 rounded-md text-sm text-gray-700 bg-white hover:bg-gray-50"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </div>
        ),
      },
    ],
    [activeTab, setShowConfirmDialog]
  );

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    setTimeout(() => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
    }, 100);
  };

  return (
    <div className="ag-theme-material" style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
      <AgGridReact
        rowData={filteredLeaves}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        rowClass="hover:bg-gray-50 cursor-pointer"
        onRowClicked={(event) => {
          if (!event.event.target.closest('button')) {
            setSelectedLeave(event.data);
          }
        }}
        pagination={true}
        paginationPageSize={20}
      />
      {selectedLeave && (
        <LeaveDetailsModal
          leave={selectedLeave}
          isOpen={!!selectedLeave}
          onClose={() => setSelectedLeave(null)}
          onUpdateParent={handleLeaveUpdate}
        />
      )}
    </div>
  );
};

export default LeaveTable;
