import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_API_URL from '../config'; // Centralized API URL
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import Modal from 'react-modal';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
  },
};

const UserProfileManagement = () => {
  const [user, setUser] = useState(null); // Store logged-in user's profile
  const [editedUser, setEditedUser] = useState(null); // For editing
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch the logged-in user's profile
  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token'); // Use token if required for authorization
      const response = await axios.get(`${BASE_API_URL}/api/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in Authorization header
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch user profile.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const openModal = () => {
    setEditedUser({ ...user }); // Clone current user data
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditedUser(null);
    setError('');
    setSuccess('');
  };

  const handleSaveProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${BASE_API_URL}/api/auth/me`,
        editedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUser(response.data); // Update the user state
      setSuccess('Profile updated successfully!');
      setError('');
      closeModal();
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading && !user) {
    return <div>Loading profile...</div>;
  }

  if (error && !user) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-6">My Profile</h1>
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
        {success && <p className="text-green-600 mb-4">{success}</p>}
        {error && <p className="text-red-600 mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <p className="bg-gray-100 p-2 rounded">{user?.name}</p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <p className="bg-gray-100 p-2 rounded">{user?.email}</p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <p className="bg-gray-100 p-2 rounded">{user?.role}</p>
        </div>
        <Button onClick={openModal} className="bg-blue-600 hover:bg-blue-700 text-white w-full">
          Edit Profile
        </Button>
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customModalStyles}
        >
          <h2 className="text-lg font-bold mb-4">Edit Profile</h2>
          <Input
            type="text"
            placeholder="Name"
            value={editedUser?.name || ''}
            onChange={(e) =>
              setEditedUser((prev) => ({ ...prev, name: e.target.value }))
            }
            className="mb-4 w-full"
          />
          <Input
            type="email"
            placeholder="Email"
            value={editedUser?.email || ''}
            onChange={(e) =>
              setEditedUser((prev) => ({ ...prev, email: e.target.value }))
            }
            className="mb-4 w-full"
          />
          <Input
            type="text"
            placeholder="Role"
            value={editedUser?.role || ''}
            disabled // Prevent editing role
            className="mb-4 w-full bg-gray-200"
          />
          <div className="flex justify-end space-x-2">
            <Button
              onClick={closeModal}
              className="bg-gray-500 hover:bg-gray-600 text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveProfile}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserProfileManagement;
